div.carousel-caption{
    color: black;
    position: relative;
    left: auto;
    right: auto;
}

ol.carousel-indicators{
    background-color: black;
}

section[id]:before {
    display: block;
    height: 30px;   /* equal to the header height */
    margin-top: -30px;  /* negative margin equal to the header height */
    visibility: hidden;
    content: "";
}

a.nav-link.glossary {
    max-width: 250px;
}

.carousel.slide {
    margin: auto;
}

.carousel.slide img {
    margin: auto;
    max-width: 80vw;
    min-width: 50vw;
}

.carousel-caption h3 {
    padding-top: 10px;
}

section.codeSample,
section.codeSampleImplementation {
    margin: 0 5vw;
}

dd.col-sm-9 {
    text-align: center;
    margin: 0 10px 7px 10px;
}

.row {
    margin-right: 5px;
    margin-left: 5px;
}

@media screen and (min-width: 1000px) {
    section.codeSample,
    section.codeSampleImplementation {
        width: fit-content;
    }

    section.codeSample {
        margin: unset;
    }

    dd {
        text-align: left;
    }

    dd.col-sm-9{
        padding-right: 125px;
        text-align: left;
        margin: unset;
    }

    .row {
        margin-right: -15px;
        margin-left: -15px;
    }
}

h5 {
    margin-top: 5px;
}

.darkMode hr {
    border-color: white;
}

.darkMode div.carousel-caption {
    color: white;
}

.ant-switch {
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    min-width: 44px;
    height: 22px;
    line-height: 22px;
    vertical-align: middle;
    background-color: rgba(0, 0, 0, 0.25);
    border: 0;
    border-radius: 100px;
    cursor: pointer;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ant-switch-handle {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 18px;
    height: 18px;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    background-color: white;
    border-radius: 100%;
}

.ant-switch-checked .ant-switch-inner {
    margin: 0 25px 0 7px;
}

.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 18px - 2px);
}

.ant-switch-inner {
    display: block;
    margin: 0 7px 0 25px;
    color: #fff;
    font-size: 12px;
    -webkit-transition: margin 0.2s;
    transition: margin 0.2s;
}

.ant-switch-checked {
    background-color: #1890ff;
}

.darkModeLabel {
    color: rgba(255,255,255,.5);
    vertical-align: middle !important;
}

.carousel-caption {
    padding-top: 30px;
}

.darkMode a {
    color: #52A4F7;
}

.darkMode h6 {
    color: #CCCCCC !important;
}